import ApiClient, { GetIndicatorsResponse } from '@ecountabl/api-client'
import {
  CompanyDataUploadRawInfo, 
  CompanyDataUpload,
  CompanyUploadMatch,
  Customer,
  Datasource, 
  Indicator,
  Question,
  Dictionary,
  API,
  ITag,
  IApiKey,
  Campaign,
  Deal,
  Value,
  IImpactArea,
  IImpactData,
  Mapping,
  Company,
  MatchedPlaidTransaction,
  User,  
} from '@ecountabl/lib'
import { REACT_APP_SERVER_ROOT } from './config'

export enum LogoSearchSource {
  CsrHub = "csrhub",
  None = ''
}

export type LogoSearchParams = {
  id?: string
  name?: string
  source?: LogoSearchSource
  domain?: string
}

export type LogoSearchResponse = {
  logourl: string
  domain?: string
}

export type ModerateCompanySearchParams = {
  sort?: 'name' | 'date-submitted' | 'source'
  query?: string
  page?: number
  source?: string
  hasLogo?: boolean
}

export type ModeratedMapping = Mapping & {
  company: Company
}

export type UserAdminView = User & {
  generationTime: Date
  source?: string
  customer?: {
    _id: string
    name: string
  }
}

class AdminApiClient extends ApiClient {
  getUsers(query: {page: number, sort: string, search: string, type: string, limit: number}): Promise<{users: UserAdminView[]}> {
    return this.get('/api/admin/users', query)
  }

  public updateUser(user: Partial<UserAdminView>): Promise<{ user: UserAdminView}> {
    return this.patch(`/api/admin/users/${user._id}`, user)
  }

  getCustomers(): Promise<Customer[]> {
    return this.get('/api/admin/customers')
  }

  getCustomer(id: string): Promise<Customer> {
    return this.get(`/api/admin/customers/${id}`)
  }

  putCustomer(c: Customer): Promise<Customer> {
    return this.put(`/api/admin/customers/${c._id}`, c)
  }

  postCustomer(c: Customer): Promise<Customer> {
    return this.post(`/api/admin/customers/`, c)
  }

  getCustomerImpactData(id: string): Promise<IImpactData[]> {
    return this.get(`/api/admin/customers/${id}/impact-data`)
  }

  putCustomerImpactData(data: IImpactData): Promise<IImpactData> {
    return this.put(`/api/admin/customers/${data.customerId}/impact-data/${data._id}`, data)
  }

  postCustomerImpactData(data: IImpactData): Promise<IImpactData> {
    return this.post(`/api/admin/customers/${data.customerId}/impact-data`, data)
  }

  getCompany(id): Promise<any> {
    return this.get('/api/admin/company/' + id)
  }

  getCompanyCount(): Promise<{ count: number }> {
    return this.get('/api/admin/company/count')
  }

  searchForLogo(params: LogoSearchParams): Promise<LogoSearchResponse> {
    return this.get(`/api/admin/company/logosearch`, params)
  }

  getSegments(): Promise<any> {
    return this.get(`/api/admin/segments`)
  }

  updateCompany(co): Promise<any> {
    const vals = Object.assign({}, co)
    delete vals._id
    return this.put('/api/admin/company/' + co._id, vals)
  }

  newCompany(co): Promise<any> {
    return this.post('/api/admin/company', co)
  }

  deleteCompany(coId: string): Promise<any> {
    return this.delete(`/api/admin/company/${coId}`)
  }

  verifyCorporateParent(nameOrId: string): Promise<{ id: string, name: string }> {
    return this.get('/api/admin/verify-corporate-parent', { nameOrId })
  }

  getUnknowns(): Promise<any> {
    return this.get('/api/admin/unknowns/')
  }
  
  setUnknownType(companyId, type): Promise<any> {
    console.warn('`setUnknownType` should be deprecated')
    return this.patch(`/api/admin/unknowns/${companyId}/type`, { type })
  }

  getMappings(query: {page: number, search: string, type: string, companiesDefined: string, sort: string}): Promise<ModeratedMapping[]> {
    return this.get('/api/admin/mappings', query)
  }

  getTransactionsForMapping(id: string, limit?: number): Promise<any> {
    return this.get('/api/admin/mappings/transactions', { mappingId: id, limit })
  }

  deleteMapping(id: string): Promise<{success: boolean}> {
    return this.delete(`/api/admin/mappings/${id}`)
  }

  mapTransaction(txid, companyId, regex?): Promise<any> {
    return this.put(`/api/admin/transactions/map/${txid}`, { companyId, regex })
  }

  companiesWithFeedback(): Promise<any> {
    return this.get(`/api/admin/companies/withfeedback`)
  }

  // action could be 'approve' or 'deny'
  manageCompanyLabel(coId, label, action): Promise<any> {
    return this.put(`/api/admin/companies/${coId}/labels`, { label, action })
  }

  getCompaniesUnderModerationAtPage(params: ModerateCompanySearchParams): Promise<any> {
    return this.get(`/api/admin/moderate-companies`, params)
  }

  getCompanyUnderModeration(id): Promise<any> {
    return this.get(`/api/admin/moderate-companies/${id}`)
  }

  getModerationSources(): Promise<any> {
    return this.get(`/api/admin/moderate-companies/sources`)
  }

  putCompanyUnderModeration(id, data): Promise<any> {
    return this.put(`/api/admin/moderate-companies/${id}`, data)
  }

  approveCompany(id, data): Promise<any> {
    return this.put(`/api/admin/moderate-companies/approve/${id}`, data)
  }

  deleteCompanyUnderModeration(id): Promise<any> {
    return this.delete(`/api/admin/moderate-companies/${id}`)
  }

  putValue(value: Value): Promise<any> {
    return this.put(`/api/admin/values/${value._id}`, value)
  }

  deleteValue(value: Value): Promise<any> {
    return this.delete(`/api/admin/values/${value._id}`)
  }

  putImpactAreas(list: IImpactArea[]): Promise<{ list: IImpactArea[] }> {
    return this.put(`/api/admin/impact-areas`, { list })
  }

  addImpactArea(cia: API.CreateImpactArea): Promise<IImpactArea> {
    return this.post('/api/admin/impact-areas', cia)
  }

  getAdminIndicators(): Promise<GetIndicatorsResponse> {
    return this.get('/api/admin/indicators')
  }

  verifyIndicators(): Promise<{ warnings: string[] }> {
    return this.get(`/api/admin/indicators/verify`)
  }

  updateIndicators(indicators: Indicator[]): Promise<any> {
    return this.put(`/api/admin/indicators`, { indicators })
  }

  duplicateIndicatorData(from: string, to: string): Promise<{ updated: number }> {
    return this.post(`/api/admin/indicators/duplicate-data`, { from, to })
  }

  getTags(): Promise<ITag[]> {
    return this.get('/api/admin/tags')
  }

  updateTags(tags: ITag[]): Promise<any> {
    return this.put(`/api/admin/tags`, tags)
  }

  deleteTag(id: string): Promise<{ message: string }> {
    return this.delete(`/api/admin/tags/${id}`)
  }

  trickleDownRatings(): Promise<{ countUpdated: number }> {
    return this.post(`/api/admin/ratings/trickle-down-ratings`, {})
  }

  updateAllUserScores(): Promise<{ message: number }> {
    return this.post(`/api/admin/scores/update-all`, {})
  }

  updateAllPlaidItems(): Promise<{ message: number }> {
    return this.post(`/api/admin/update-plaid-items`, {})
  }

  updatePerformanceByValue(): Promise<{ message: string }> {
    return this.post(`/api/admin/update-performance-by-value`, {})
  }

  signDataUpload(file): Promise<API.SignFileUploadResponse> {
    return this.get(`/api/admin/files/sign-data-upload`, {
      name: file.name,
      type: file.type,
    })
  }

  signLogoUpload(file): Promise<API.SignFileUploadResponse> {
    return this.get(`/api/admin/files/sign-logo-upload`, {
      name: file.name,
      type: file.type,
    })
  }

  uploadFile(signedRequest, file): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(signedRequest, {
        method: 'PUT',
        body: file,
        headers: {
          'Cache-Control': 'public, max-age=2592000',
          // necessary header in order to upload to Azure Blob Storage
          "x-ms-blob-type": "BlockBlob"
        }
      }).then(async (res) => {
        if (res.status >= 200 && res.status < 300) {
          resolve(res)
        } else {
          reject(res)
        }
      })
    })
  }

  getCompanyDataUploads(): Promise<CompanyDataUpload[]> {
    return this.get('/api/admin/data/company-csv-upload')
  }

  getCompanyDataUpload(id: string): Promise<CompanyDataUpload> {
    return this.get(`/api/admin/data/company-csv-upload/${id}`)
  }

  createCompanyCSVUpload(url: string, name: string): Promise<CompanyDataUpload> {
    return this.post('/api/admin/data/company-csv-upload', { url, name })
  }

  getCompanyCSVUploadRawInfo(id: string): Promise<CompanyDataUploadRawInfo> {
    return this.get(`/api/admin/data/company-csv-upload/${id}/rawinfo`)
  }

  updateUploadMapping(id: string, name: string, match: CompanyUploadMatch): Promise<API.DefaultResponse> {
    return this.put(`/api/admin/data/company-csv-upload/${id}/match/${encodeURIComponent(name)}`, match)
  }

  saveCompanyCSVUpload(upload: CompanyDataUpload): Promise<CompanyDataUpload> {
    return this.patch(`/api/admin/data/company-csv-upload/${upload._id}`, upload)
  }

  doCompanyCSVUpload(id: string): Promise<CompanyDataUpload> {
    return this.post(`/api/admin/data/company-csv-upload/${id}/process`)
  }

  companyCSVUploadPreviewMatches(id: string): Promise<CompanyDataUpload> {
    return this.get(`/api/admin/data/company-csv-upload/${id}/preview`)
  }

  getTransactions(sort, page, showModerated, search): Promise<any> {
    const _page = page || 1
    const encodedSearch = encodeURIComponent(search || '')

    return this.get('/api/admin/transactions', {
      sort,
      page: _page,
      search: encodedSearch,
      showModerated
    })
  }

  putDatasource(d: Datasource): Promise<Datasource> {
    return this.put(`/api/admin/datasources/${d._id}`, d) as Promise<Datasource>
  }

  createDatasource(d: Datasource): Promise<Datasource> {
    return this.post(`/api/admin/datasources`, d) as Promise<Datasource>
  }

  createApiKey(label: string): Promise<IApiKey> {
    return this.post('/api/api-keys', { label })
  }

  getApiKeys(): Promise<IApiKey[]> {
    return this.get('/api/api-keys')
  }

  deleteApiKey(id: string): Promise<IApiKey[]> {
    return this.delete(`/api/api-keys/${id}`)
  }
   
  getCampaigns(): Promise<Campaign[]> {
    return this.get('/api/admin/deals/campaigns')
  }

  // syncs campaigns from providers, can take a while
  syncCampaigns(): Promise<API.DefaultResponse> {
    return this.post('/api/admin/deals/campaigns/sync')
  }

  getDeals(campaignId: string): Promise<Deal[]> { 
    return this.get(`/api/admin/deals/${campaignId}`)
  }

  updateCampaign(campaign: Campaign): Promise<Campaign> {
    return this.put(`/api/admin/deals/campaigns/${campaign._id}`, campaign)
  }

  async getQuestions(): Promise<Question[]> {
    const { questions } = await this.get('/api/admin/questions')
    return questions
  }

  getQuestion(id: string): Promise<Question> {
    return this.get(`/api/admin/questions/${id}`)
  }

  saveQuestion(q: Question): Promise<Question> {
    return this.post(`/api/admin/questions`, q)
  }

  reindexElasticsearch(): Promise<API.DefaultResponse> {
    return this.post('/api/admin/reindex-elasticsearch')
  }
}

const client = new AdminApiClient({
  token: window.localStorage.getItem('_token'),
  endpoint: REACT_APP_SERVER_ROOT,
  onTokenError: async (err: string): Promise<void> => {
    // TODO: implement logout
    window.localStorage.removeItem('_token')
    window.location.pathname = '/login'
  },
  onTokenUpdate: async (token): Promise<void> => {
    window.localStorage.setItem('_token', token)
  }
})

export default client
