import { Dictionary } from "./misc"

enum BankingPlatforms {
  finastra = 'finastra'
}

export type BankingPlatform = keyof typeof BankingPlatforms

export type CustomerConfig = {
  theme?: string               // should map to built themes
  // map of [valueslug]: sort order
  valuesSortOrder?: Dictionary<number>
  topTags?: string[]
}

export interface IImpactData {
  _id?: string
  customerId: string
  monthEnding: string
  data?: Dictionary<{
    quantity?: number
    amount?: number
  }>
}

export interface DataPoint {
  label: string
  value: string
  impactAreaId?: string
}

export interface Partnership {
  imgUrl: string
  imgHeight: number
  imgWidth: number
  link?: string
}

export const PARTNERSHIP_MAX_IMG_DIMS = {
  height: 70,
  width: 100
}

export type FinancialIntegration = {
  platform?: BankingPlatform

  // finastra
  finastraTenantId?: string
  finastraClientId?: string
  finastraClientSecret?: string
  // finastra B2B
  finastraB2BTenant?: string
  finastraB2BClientId?: string
  finastraB2BClientSecret?: string
}

export const RequestMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
}

export type CustomRequest = {
  name: string
  requestMethod: keyof typeof RequestMethods
  url: string
  headers: string
  body?: string
}

export interface Customer {
  _id: string
  name: string
  config?: CustomerConfig
  logo?: string
  integrations?: FinancialIntegration[]
  willNotFund?: Dictionary<boolean>
  highlightedDataPoints?: DataPoint[]
  partnerships?: Partnership[]
  partnershipHeader?: string
  partnershipSubtext?: string
  customTexts: Dictionary<string>
  customRequests: CustomRequest[]
}

export const WillNotFund = {
  fossilFuel: {
    label: 'Fossil Fuels',
    icon: {
      family: 'MaterialIcons',
      name: 'local-gas-station'
    }
  },
  firearms: {
    label: 'Firearms and Ammunition',
    icon: {
      family: 'MaterialCommunityIcons',
      name: 'ammunition'
    }
  },
  privatePrisons: {
    label: 'Private Prisons',
    icon: {
      family: 'FontAwesome',
      name: 'building'
    }
  },
  tobacco: {
    label: 'Tobacco',
    icon: {
      family: 'MaterialCommunityIcons',
      name: 'smoking'
    }
  },
  paydayLenders: {
    label: 'Payday Lenders',
    icon: {
      family: 'Foundation',
      name: 'dollar-bill'
    }
  }
}

