import React, { useState } from 'react';
import api from './api-client'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUser } from './redux/slices/user'
import logo from './img/oneethos.png'
import Spinner from './spinner'

const SignUp = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [error, setError] = useState('')
  const [birthYear, setBirthYear] = useState<number>(2000)
  const [gender, setGender] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setSubmitting(true)

    api.signUp({
      firstName,
      email,
      lastName,
      password,
      city,
      state,
      birthYear: birthYear.toString(),
      gender
    }).then(res => {
      api.setToken(res.token)
      window.localStorage.setItem('_token', res.token)
      setSubmitting(false)
      dispatch(setUser(res.user))
    }).catch(ex => {
      setSubmitting(false)

      if (ex.errors) {
        setError(ex.errors)
      } else {
        setError(ex.msg || ex.message)
      }
    })
  }

  return <div className="mini-form">
    <a href="https://ecountabl.com"><img src={logo} alt="logo" /></a>
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>First Name</label>
        <input
          type="text"
          onChange={ev => setFirstName(ev.target.value)}
          value={firstName}
          className="form-control"
          placeholder="First Name"
          required
          autoFocus
        />
      </div>
      <div className="form-group">
        <label>Last Name</label>
        <input
          type="text"
          onChange={ev => setLastName(ev.target.value)}
          value={lastName}
          className="form-control"
          placeholder="Last Name"
          required
        />
      </div>

      <div className="form-group">
        <label>Email</label>
        <input
          onChange={ev => setEmail(ev.target.value)}
          type="text"
          className="form-control"
          placeholder="Email"
          required
        />
      </div>

      <div className="form-group">
        <label>Password</label>
        <input
          onChange={ev => setPassword(ev.target.value)}
          type="password"
          id="inputPassword"
          className="form-control"
          placeholder="Password"
          required
        />
      </div>

      <div className="form-group">
        <label>City</label>
        <input
          onChange={ev => setCity(ev.target.value)}
          type="text"
          className="form-control"
          placeholder="City"
          required
        />
      </div>

      <div className="form-group">
        <label>State</label>
        <input
          onChange={ev => setState(ev.target.value)}
          type="text"
          className="form-control"
          placeholder="State"
          required
        />
      </div>

      <div className="form-group">
        <label>Year of Birth</label>
        <input
          onChange={ev => setBirthYear(ev.target.value)}
          type="number"
          className="form-control"
          value={birthYear}
          required
        />
      </div>

      <div className="form-group">
        <label>Gender</label>
        <select className="form-control" value={gender} onChange={ev => setGender(ev.target.value)}>
          <option></option>
          <option value="female">Female</option>
          <option value="male">Male</option>
          <option value="non-binary">Non-Binary</option>
          <option value="prefer-not-to-say">Prefer not to say</option>
        </select>
      </div>

      <button
        disabled={submitting}
        className="btn btn-lg btn-primary btn-block"
        type="submit">{submitting ? <Spinner /> : "Sign Up"}</button>
      <button
        className="btn btn-lg btn-secondary btn-block"
        onClick={() => history.go(-1)}
        type="button">Back</button>
      {error && <div className="alert alert-danger">Login error(s): {
        Array.isArray(error) ? <ul>
          {error.map((e, i) => <li key={i}>{e}</li>)}
        </ul> : <span>{JSON.stringify(error)}</span>
      }</div>}
    </form>
  </div>
}

export default SignUp
