import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Company } from '@ecountabl/lib'
import api from './api-client'
import actions from './redux/actions'
import Spinner from './spinner'
import { FaCity, FaStore, FaExternalLinkAlt } from "react-icons/fa"

type CompanyDropDownProps = {
  companies: Company[];
  resultsStyle?: "dropdown-only" | "select" | "regex";   // hide buttons | show select button | company name is select
  onSelect: (id: string, co?: Company) => void;
  buttonText?: string;
  onRegex? : (Company) => void;
}

export const CompanyDropDownList: React.FC<CompanyDropDownProps> = ({
  companies,
  onSelect,
  buttonText,
  resultsStyle,
  onRegex
}) => {

  const dropdownOnly = resultsStyle == "dropdown-only"

  if (onRegex && resultsStyle == "regex") {
    return (
      <ul className="list-group">
        {companies.map((co: any) => {
          const Icon = co.corporate_parent_id ? FaStore : FaCity
          const color = co.corporate_parent_id ? '#aaa' : 'black'
  
          return (
            <li
              key={co._id}
              className="list-group-item"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flex: 10,
                cursor: dropdownOnly ? 'pointer' : 'default'
              }}
              onClick={() => {
                if (dropdownOnly) {
                  onSelect(co._id, co)
                }
              }}
            >
              <div style={{ marginRight: '1em', color, flex: 6 }}>
              <span title={co.corporate_parent_id ? "child company" : "no parent"}>
                <Icon size="1.3em" /></span>
                &nbsp;
                <p style={{textDecoration: "underline", display: "inline", cursor: "pointer"}} onMouseDown={() => onSelect(co._id, co)}>
                  {co.name}
                </p>              
              </div>
              {!dropdownOnly ? <div style={{ flex: 4, textAlign: 'right' }}>
                <button
                  className="btn btn-primary dd-select"
                  type="button"
                  onMouseDown={() => onRegex(co)}
                >
                  {"Regex"}
                </button>
                <a
                style={{ marginLeft: '0.5em', cursor: 'pointer' }}
                onClick={() => {
                  window.open('/companies/' + co._id, 'new')
                }}><FaExternalLinkAlt /></a>
                </div> : null}
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <ul className="list-group">
      {companies.map((co: any) => {
        const Icon = co.corporate_parent_id ? FaStore : FaCity
        const color = co.corporate_parent_id ? '#aaa' : 'black'

        return (
          <li
            key={co._id}
            className="list-group-item"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flex: 10,
              cursor: dropdownOnly ? 'pointer' : 'default'
            }}
            onClick={() => {
              if (dropdownOnly) {
                onSelect(co._id, co)
              }
            }}
          >
            <div style={{ marginRight: '1em', color, flex: 6 }}>
              <span title={co.corporate_parent_id ? "child company" : "no parent"}><Icon size="1.3em" /></span>
              &nbsp;
              {co.name}              
            </div>
            {!dropdownOnly ? <div style={{ flex: 4, textAlign: 'right' }}>
              <button
                className="btn btn-primary dd-select"
                type="button"
                onMouseDown={() => onSelect(co._id, co)}
              >
                {buttonText || "Select"}
              </button>
              <a
                style={{ marginLeft: '0.5em', cursor: 'pointer' }}
                onClick={() => {
                  window.open('/companies/' + co._id, 'new')
                }}><FaExternalLinkAlt /></a>
            </div> : null}
          </li>
        )
      })}
    </ul>
  )
}

export type CompanySearchProps = {
  onInputChange?: (ev) => void;
  onSelect: (id: string, co?: Company) => void;
  initialSearch?: string;
  buttonText?: string;
  autoSearch?: boolean;
  autoFocus?: boolean;
  hideNoResults?: boolean;
  inputPrependChildren?: JSX.Element;
  inputAppendChildren?: JSX.Element;
  style?: React.CSSProperties;
  placeholder?: string;
  resultsStyle?: "dropdown-only" | "select" | "regex";   // hide buttons | show select button | company name is select
  clearAfterSelect?: boolean;
  onRegex?: (co: Company) => void;
}

const CompanySearch = ({
  onSelect,
  initialSearch,
  buttonText,
  autoSearch,
  autoFocus = true,
  hideNoResults = false,
  inputPrependChildren,
  inputAppendChildren,
  onInputChange,
  placeholder,
  style,
  resultsStyle,
  clearAfterSelect,
  onRegex
}: CompanySearchProps) => {
  const [companies, setCompanies] = useState([])
  const [value, setValue] = useState(initialSearch || '')
  const [showResults, setShowResults] = useState(false)
  const [searching, setSearching] = useState(true)

  const inputRef = useRef(null)
  const elRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (autoSearch) {
      search(value)
    }
  }, [autoSearch, value])

  useEffect(() => {
    setValue(initialSearch || '')
    setCompanies([])
    inputRef?.current?.select()
  }, [initialSearch])

  useEffect(() => {
    const handleClickOutside = ev => {
      if (elRef.current && !elRef.current.contains(ev.target)) {
        setShowResults(false)
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const search = (term: string) => {
    setSearching(true)
    api.tokenizedSearch(term, 100)
      .then(({ companies }) => {
        setCompanies(companies)
        setShowResults(true)
      })
      .catch((ex) => {
        dispatch(actions.ajaxErrorHandler(ex))
      })
      .finally(() => setSearching(false))
  }

  const onQueryChange = (ev: any) => {
    const { value } = ev.target

    setValue(value)
    if (value.length > 0) {
      search(value)
    } else {
      setCompanies(companies)
    }
  }

  const showNoResults = (!hideNoResults && !companies.length)

  return (
    <div className="co-search" style={style} ref={elRef}>
      <div className="input-group">
        
        {!inputPrependChildren ? null :
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">{inputPrependChildren}</span>
          </div>
        }
        
        <input
          type="text"
          className="form-control"
          value={value}
          onFocus={ev => {
            if (value?.length > 2) {
              search(value)
            } else {
              setCompanies(companies)
            }
          }}
          autoFocus={autoFocus}
          ref={inputRef}
          onChange={ev => {
            onQueryChange(ev)
            if (onInputChange) {
              onInputChange(ev)
            }
          }}
          placeholder={placeholder || "Search"}
        />

        {!inputAppendChildren ? null :
          <div className="input-group-append">
            <span className="input-group-text" id="basic-addon1">{inputAppendChildren}</span>
          </div>
        }
      </div>
      {(companies.length && showResults) ? (
        <CompanyDropDownList
          companies={companies}
          resultsStyle={resultsStyle}
          onSelect={(id, co) => {
            onSelect(id, co)
            setShowResults(false)
            setCompanies([])
            if (clearAfterSelect) {
              setValue('')
            }
          }}
          buttonText={buttonText}
          onRegex={(co) => {onRegex(co)}}
        />
      ) : null}
      {showNoResults ? <div>no companies found for search</div> : null}
    </div>
  )
}

export const CompanySearchWrapper: React.FC<Props> = props => {
  return (
    <div className="map-unknown">
      <h3>Map to Company</h3>
      <CompanySearch {...props} />
    </div>
  )
}

export default CompanySearch