import { CustomRequest, Customer, RequestMethods } from "@ecountabl/lib";
import React from "react"

type Props = {
  onChange: (requests: CustomRequest[]) => void;
  customer: Partial<Customer>;
}

const CustomRequests = ({onChange, customer}:Props) => {
  return (
    <div className="custom-text-field-section">
      <h4>Custom Requests</h4>
      {
        customer.customRequests?.map((request, index) => {
          return (
            <div key={index} className="custom-text-field-row">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                value={request.name}
                onChange={(e) => {
                  const customRequests = [...customer.customRequests || []]
                  customRequests[index].name = e.target.value
                  onChange(customRequests)
                }}
              />
              <label>Request Method</label>
              <select
                className="form-control"
                value={request.requestMethod}
                onChange={(e) => {
                  const customRequests = [...customer.customRequests || []]
                  customRequests[index].requestMethod = e.target.value as keyof typeof RequestMethods
                  onChange(customRequests)
                }}
              >
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="PUT">PUT</option>
                <option value="DELETE">DELETE</option>
              </select>
              <label>URL</label>
              <input
                type="text"
                className="form-control"
                value={request.url}
                onChange={(e) => {
                  const customRequests = [...customer.customRequests || []]
                  customRequests[index].url = e.target.value
                  onChange(customRequests)
                }}
              />
              <label>Headers</label>
              <textarea
                className="form-control"
                value={request.headers}
                onChange={(e) => {
                  const customRequests = [...customer.customRequests || []]
                  customRequests[index].headers = e.target.value
                  onChange(customRequests)
                }}
                style={{width: "100%"}}
                placeholder="{}"
              />
              <label>Body</label>
              <textarea
                className="form-control"
                value={request.body}
                onChange={(e) => {
                  const customRequests = [...customer.customRequests || []]
                  customRequests[index].body = e.target.value
                  onChange(customRequests)
                }}
                style={{width: "100%"}}
                placeholder="{}"
              />
               <i className="fa fa-trash" style={{
              cursor: 'pointer',
              fontSize: '1.5em',
              margin: '0.5em'
            }} onClick={() => {
              const customRequests = [...customer.customRequests]
              customRequests.splice(index, 1)
              onChange(customRequests)
            }} />
            </div>
          )
        })
      }
      <div>
          <button
            type="button"
            className="btn btn-normal"
            onClick={() => {
              const customRequests = [...customer.customRequests || []]
              customRequests.push({name: '', requestMethod: 'GET', url: '', headers: ''})
              onChange(customRequests)
            }}
          >Add New Request <i className="fa fa-plus-circle" /></button>
        </div>
    </div>
  )
}

export default CustomRequests