import { Customer } from "@ecountabl/lib"
import React from "react"

type Props = {
  onChange: (texts: {name: string, value: string}[]) => void;
  customTexts: {name: string, value: string}[];
}

const CustomTextForm:React.FC<Props> = ({customTexts, onChange}:Props) => {
  return (
    <div className="custom-text-field-section">
      <h4>Custom Text Fields</h4>
      {
        customTexts?.map(({name, value}, index) => {
          return (
            <div key={index} className="custom-text-field-row">
              <input
                type="text"
                className="form-control"
                value={name || ""}
                onChange={(e) => {
                  const newCustomTexts = [...customTexts || []]
                  newCustomTexts[index].name = e.target.value
                  onChange(newCustomTexts)
                }}
                placeholder="Text Name"
              />
              <textarea
                className="form-control"
                value={value || ""}
                onChange={(e) => {
                  const newCustomTexts = [...customTexts || []]
                  newCustomTexts[index].value = e.target.value
                  onChange(newCustomTexts)
                }}
                style={{width: "100%"}}
                placeholder={"Text to display"}
              />
              <i className="fa fa-trash" style={{
              cursor: 'pointer',
              fontSize: '1.5em',
              margin: '0.5em'
            }} onClick={() => {
              const newCustomTexts = [...customTexts]
              newCustomTexts.splice(index, 1)
              onChange(newCustomTexts)
            }} />
            </div>
          )
        })
      }
      <div>
          <button
            type="button"
            className="btn btn-normal"
            onClick={() => {
              const newCustomTexts = [...customTexts || []]
              newCustomTexts.push({name: "", value: ""})
              onChange(newCustomTexts)
            }}
          >Add New Custom Text <i className="fa fa-plus-circle" /></button>
        </div>
    </div>
  )

}

export default CustomTextForm