import React, { useEffect, useState } from "react"
import Spinner from "./spinner"
import Pagination from "./pagination";
import { User } from "@ecountabl/lib";
import api, { UserAdminView } from './api-client'
// styles
import './user-manager.scss'
import { useDispatch, useSelector } from "react-redux";
import notify from './notifications-helper'

const sortOptions = [
  { value: "oldest", label: "Oldest" },
  { value: "newest", label: "Newest" },
  { value: "email a-z", label: "Email A-Z" },
  { value: "email z-a", label: "Email Z-A" }
]

const userTypeOptions = [
  { value: "all", label: "All" },
  { value: "super-user", label: "Super User" },
  { value: "user", label: "User" }
]

type Query = {
  page: number;
  // sort can be any value in sortOptions
  sort: "oldest" | "newest" | "email a-z" | "email z-a";
  type: "all" | "super-user" | "user";
  search: string;
  limit: number;
}

const UserManager = () => {
  const [users, setUsers] = useState<UserAdminView[]>([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState<Query>({
    page: 1,
    sort: "newest",
    search: "",
    type: "all",
    limit: 10
  })

  const dispatch = useDispatch()

  const onChangeQuery = (key: keyof Query, value: any) => {
    setQuery({
      ...query,
      [key]: value
    })
  }

  const changeUserLevel = async (index) => {
    const user = users[index]
    // prompt the user via window to confirm
    if (!window.confirm(
      user.isSuperUser ? 
      `Are you sure you want to revoke SuperUser status from ${user.email}?`
      : `Are you sure you want to make ${user.email} a SuperUser?`
    )) {
      return
    }
    
    const newLevel = user.isSuperUser ? false : true
    setLoading(true)
    const newUser = await api.updateUser({...user, isSuperUser: newLevel})
    setUsers([
      ...users.slice(0, index),
      newUser.user,
      ...users.slice(index + 1)
    ])
    setLoading(false)
  }

  useEffect(() => {
    fetchUsers()
  }, [query.page, query.sort, query.type])

  const fetchUsers = async () => {
    if (query.search.length > 0 && query.search.length < 3) {
      dispatch(notify.error({message: "Search must be at least 3 characters"}))
      return
    }

    setLoading(true)

    // fetch users from API
    try {
      const {users} = await api.getUsers(query)
      setUsers(users)
    } catch (error) {
      dispatch(notify.error({message: error.message || "Failed to fetch users"}))
      setLoading(false)
      return
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="users-mgr">
      <div style={{ width: '100%' }}>
        <div>
          <h3>User Moderation</h3>
        </div>
        {loading ? <Spinner /> : null}
        <div className='user-mgr-body'>
          <div className='user-control-row'>
            <div className='user-control'>
              <label>Page</label>
              <Pagination
                page={query.page}
                onChange={page => {
                  onChangeQuery("page", page)
                }}
              />
            </div>
            <div className='user-control max-width'>
              <label>Limit</label>
              <select className='form-control' value={query.limit} onChange={(e) => onChangeQuery("limit", e.target.value)}>
                {[10, 25, 50, 100].map(limit => (
                  <option key={limit} value={limit}>{limit}</option>
                ))}
              </select>
            </div>
            <div className='user-control max-width'>
              <label>Sort</label>
              <select className='form-control' value={query.sort} onChange={(e) => onChangeQuery("sort", e.target.value)}>
                {sortOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className='user-control max-width'>
              <label>Type</label>
              <select className='form-control' value={query.type} onChange={(e) => onChangeQuery("type", e.target.value)}>
                {userTypeOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            
            <div className='user-control max-width'>
              <label>Search</label>
              <input
                className='form-control'
                type="text"
                value={query.search}
                onChange={(e) => onChangeQuery("search", e.target.value)}
                placeholder='Search...'
              />
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={fetchUsers}
              disabled={loading}
            >
              <i className="fa fa-search" />
            </button>
          </div>
          { users.length === 0 && <h5>No Users found</h5>}
          { users.length > 0 && users.map((user, idx) => (
              <UserItem
                key={idx}
                user={user}
                index={idx}
                changeUserLevel={changeUserLevel}
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}

type UserItemProps = {
  user: UserAdminView;
  index: number;
  changeUserLevel: (index: number) => void;
}

const UserItem = ({user, index, changeUserLevel}: UserItemProps) => {
  const curUser = useSelector(state => state.user)
  const [expanded, setExpanded] = useState(false)
  const [expandedAccounts, setExpandedAccounts] = useState(false)
  const [expandedImpactAreas, setExpandedImpactAreas] = useState(false)
  const numAccounts = user.accounts.length
  const hasValues = Object.values(user.valuedImpactAreaMap).some(val => val)
  return (
    <div className="user-item">
      <div className="main-row interactive" onClick={() => setExpanded(!expanded)} >
        <div className="item">{user.email}</div>
        <div className="item-sm">
          <a href={user.customer ? `/customers/${user.customer._id}` : null}>
            {user.customer ? user.customer.name : "No Customer"}
          </a>
        </div>
        
        <div className="item-sm">{user.isSuperUser ? "Super User" : "User"}</div>
        <i className={`fa fa-chevron-${expanded ? "up" : "down"}`}/>
      </div>
      {
        expanded && (
          <div className="expanded-section">
            <div className="user-detail-row">
              <div className="detail">
                <label className="label">_id:</label>
                <div className="value">{user._id}</div>
              </div>
              <div className="detail">
                <label className="label">Generation Time:</label>
                <div className="value">{new Date(user.generationTime).toLocaleString()}</div>
              </div>
            </div>
            <div className="user-detail-row">
              <div className="detail">
                <label className="label">Email:</label>
                <div className="value">{user.email}</div>
              </div>
              <div className="detail">
                <label className="label">Super User:</label>
                {/* display falsey value */}
                <div className="value">{(user.isSuperUser || false).toString()}</div>
              </div>
            </div>
            <div className="user-detail-row">
              <div className="detail">
                <label className="label">Onboarded:</label>
                <div className="value">{(user.hasOnboarded || false).toString()}</div>
              </div>
              <div className="detail">
                <label className="label">Accepted Terms:</label>
                <div className="value">{(user.hasAcceptedTerms || false).toString()}</div>
              </div>
              <div className="detail">
                <label className="label">Needs Value Selection:</label>
                <div className="value">{(user.needsValueSelection || false).toString()}</div>
              </div>
            </div>
            <div className="user-detail-row">
              <div className="detail">
                <label className="label">Customer:</label>
                <div className="value">
                  <a href={user.customer ? `/customers/${user.customer._id}` : null}>
                    {user.customer ? user.customer.name : "No Customer"}
                  </a>
                </div>
              </div>
              <div className="detail">
                <label className="label">Tenant:</label>
                <div className="value">{user.tenant || "undefined"}</div>
              </div>
              <div className="detail">
                <label className="label">Source:</label>
                <div className="value">{user.source || "undefined"}</div>
              </div>
            </div>
            {/* accounts of user */}
            <div className="user-detail-row">
              <div className="detail">
                <label className="label">Accounts:</label>
                <div className="value">
                  {numAccounts} account{numAccounts == 1 ? " " : "s "}
                  {
                    numAccounts > 0 && (
                      <i className={`fa fa-${expandedAccounts ? "minus" : "plus"} interactive`} onClick={() => setExpandedAccounts(!expandedAccounts)}/>
                    )
                  }
                </div>
              </div>
            </div>
            {
              expandedAccounts && (
                <div className="user-detail-row">
                  {user.accounts.map((account, idx) => (
                    <div key={idx} className="account">
                      <div>Institution: {account.institution.name}</div>
                      <div>Status: {account.status}</div>
                    </div>
                  ))}
                </div>
              )
            }
            {/* valued impact area map */}
            <div className="user-detail-row">
              <div className="detail">
                <label className="label">Values:</label>
                <div className="value">
                  {
                    hasValues ? (
                      <>
                        See {expandedImpactAreas ? "less" : "more"}{" "}
                        <i className={`fa fa-${expandedImpactAreas ? "minus" : "plus"} interactive`} onClick={() => setExpandedImpactAreas(!expandedImpactAreas)}/>
                      </>
                    ) : (
                      "No values defined"
                    )
                  }
                </div>
              </div>
            </div>
            {
              expandedImpactAreas && (
                <div className="user-detail-row">
                  {Object.keys(user.valuedImpactAreaMap).map((key, idx) => (
                    <div key={idx} className="account">
                      <div>{key}: <i className={`fa fa-${user.valuedImpactAreaMap[key] ? 'check' : 'close'}`}/></div>
                    </div>
                  ))}
                </div>
              )
            }
            {/* user action items */
              curUser.user._id !== user._id && (
                <div className="user-actions-row">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => changeUserLevel(index)}
                  >
                    {user.isSuperUser ? "Revoke SuperUser" : "Make SuperUser"}
                  </button>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  )
}

export default UserManager