import React from 'react'
import { Customer, FinancialIntegration } from '@ecountabl/lib'
import FormField from '../form-field'
import { REACT_APP_SERVER_ROOT } from '../config'

type FinastraFormProps = {
  integration: FinancialIntegration & { isModified?: boolean }
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
  onDelete: () => void
}

export const FinastraForm = ({ integration, onChange, onDelete }: FinastraFormProps) => {
  const { finastraTenantId, finastraClientId, finastraClientSecret, isModified } = integration
  const canLogin = (finastraTenantId && finastraClientId && finastraClientSecret)

  const fields = [{
    field: 'finastraTenantId',
    label: 'Finastra Tenant ID',
    onChange,
    type: 'text'
  }, {
    field: 'finastraClientId',
    label: 'Finastra App Client ID',
    onChange,
    type: 'text',
    autocomplete: 'off'
  }, {
    field: 'finastraClientSecret',
    label: 'Finastra App Client Secret',
    onChange,
    type: 'password',
    autocomplete: 'off'
  }, {
    field: 'finastraB2BTenant',
    label: 'Finastra B2B Tenant',
    onChange,
    type: 'text',
    autocomplete: 'off'
  }, {
    field: 'finastraB2BClientId',
    label: 'Finastra B2B Client ID',
    onChange,
    type: 'text',
    autocomplete: 'off'
  }, {
    field: 'finastraB2BClientSecret',
    label: 'Finastra B2B Client Secret',
    onChange,
    type: 'password',
    autocomplete: 'off'
  }]

  return <div className="finastra-form">
    {fields.map(f => (
      <FormField
        value={integration[f.field] || ''}
        key={f.field}
        {...f}
      />
    ))}
    <div style={{ display: 'flex' }}>
      <span className="btn btn-danger btn-sm" onClick={onDelete}>
        <i className="fa fa-trash" />
      </span>
      <div style={{ marginLeft: '0.5em', display: 'inline-block' }}>
        {canLogin ? (
          isModified
            ? <div style={{ fontSize: '0.8em', maxWidth: '200px', display: 'inline-block' }}>Changes must be saved before you can login via Finastra</div>
            : <a
              className="btn btn-link btn-sm"
              target="_blank"
              href={`${REACT_APP_SERVER_ROOT}/api/oauth/finastra/oidc/${finastraTenantId}`}
            >Login via Finastra <i className="fa fa-external-link"></i></a>
        ) : null}
      </div>
    </div>
  </div >
}

export default FinastraForm