type PaginationProps = {
  page: number,
  onChange: (newPage: number) => void,
  maxPage?: number
}

const Pagination = (props: PaginationProps) => {
  const { page, onChange, maxPage } = props

  return (
    <div className="epagination">
      <button
        className="btn btn-primary btn-sm"
        disabled={page === 1}
        onClick={() => onChange(page - 1)}
      >
        &lt;
      </button>
      <div className="page-no">{page}</div>
      <button
        className="btn btn-primary btn-sm"
        onClick={() => onChange(page + 1)}
        disabled={maxPage && page === maxPage}
      >
        &gt;
      </button>
    </div>
  )
}

export default Pagination