import React, { useState, useRef, useEffect } from 'react'
import { FaUserCircle } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { logout } from './redux/slices/user'
import { useDispatch } from 'react-redux'
import CompanySearch from './company-search'
import AdminNavMenuDropdown from './admin-nav-menu-dropdown'

type Props = {
  showCompanySearch: boolean
}

const NavUserDropdown: React.FC<Props> = ({ showCompanySearch }: Props) => {
  const dispatch = useDispatch()

  const history = useHistory()

  return (
    <div className="navbar-nav">
      {showCompanySearch && <div className="nav-item" style={{
        display: 'flex',
        alignItems: 'center',
        minWidth: '300px'
      }}>
        <CompanySearch
          onSelect={(id, co) => {
            history.push(`/companies/${id}`)
          }}
          placeholder="Company Search"
          clearAfterSelect
          hideNoResults={true}
          autoFocus={false}
          resultsStyle='dropdown-only'
          style={{ width: '100%' }}
        />
      </div>}
      <AdminNavMenuDropdown
        labelElement={<FaUserCircle style={{ fontSize: '2em', color: '#666' }} />}
        menuElements={<a className="dropdown-item" href="#"
          onClick={() => {
            dispatch(logout())
            history.push('/login')
          }}
        >Logout</a>}
        menuStyle={{
          right: 0
        }}
      />
    </div>
  )
}

export default NavUserDropdown
