import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import logo from './img/oneethos.png'


const Home = () => {
  const history = useHistory()

  return (
    <div className="home mini-form">
      <a href="https://ecountabl.com"><img src={logo} alt="logo" /></a>
      <button
        onClick={() => history.push('/login')}
        className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
      <button
        onClick={() => history.push('/signup')}
        className="btn btn-lg btn-primary btn-block" type="submit">Create Account</button>
    </div>
  )
}

export default Home
