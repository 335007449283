import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import actions from './redux/actions'
import api, { ModeratedMapping } from './api-client'
import Spinner from './spinner'
import { Company, Mapping } from '@ecountabl/lib'
import { on } from 'events'
import { FaExternalLinkAlt } from 'react-icons/fa'
import notify from "../src/notifications-helper"
import Pagination from './pagination'


type Query = {
  page: number;
  type: "all" | "regex" | "label" | "merchant";
  search: string;
  companiesDefined: "all" | "yes" | "no";
  sort: "lastUpdated" | "key"
}

const MappingManager = () => {
  const [mappings, setMappings] = useState<ModeratedMapping[]>([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState<Query>({
    page: 1,
    type: "all",
    search: "",
    companiesDefined: "all",
    sort: "lastUpdated"
  })
  const [transactions, setTransactions] = useState<any[]>([])
  const [selectedMapping, setSelectedMapping] = useState<string>(null)

  const dispatch = useDispatch()

  useEffect(() => {
    fetchMappings()
    setSelectedMapping(null)
  }, [query.page, query.type, query.companiesDefined, query.sort])

  const fetchMappings = () => {
    setLoading(true)
    api.getMappings(query)
      .then(mappingVals => {
        setMappings(mappingVals)
      })
      .catch((ex) => dispatch(actions.ajaxErrorHandler(ex)))
      .finally(() => setLoading(false))
  }

  const fetchTransactions = (mappingId: string) => {
    if (selectedMapping === mappingId) {
      setSelectedMapping(null)
      setTransactions([])
      return
    }
    setSelectedMapping(mappingId)
    api.getTransactionsForMapping(mappingId)
      .then(res => {
          setTransactions(res)
      })
      .catch((ex) => dispatch(actions.ajaxErrorHandler(ex)))
  }

  const deleteMapping = (mappingId: string) => {
    setLoading(true)
    api.deleteMapping(mappingId)
      .then((res) => {
        if (res.success) {
          setMappings(mappings.filter(mapping => mapping._id.toString() !== mappingId))
          dispatch(notify.success({ message: 'Mapping deleted successfully' }))
        }
        else dispatch(notify.error({ message: 'Deleting mapping failed' }))
      })
      .catch((ex) => dispatch(actions.ajaxErrorHandler(ex)))
      .finally(() => setLoading(false))
  }

  const onChangeQuery = (name: "page" | "type" | "search" | "companiesDefined" | "sort", value: string | number) => {
    setQuery({
      ...query,
      [name]: value
    })
  }

  return (
    <div className="mappings-mgr">
      <div style={{ width: '100%' }}>
        <div className="tx-hdr">
          <h3>Mapping Moderation</h3>
        </div>
        {loading ? <Spinner /> : null}
        <div className='mapping-mgr-body'>
            <div className='col primary' style={{display: "flex",flex: 1}}>
            <div className='mapping-control-row'>
              <div className='mapping-control'>
                <label>Page</label>
                <Pagination
                  page={query.page}
                  onChange={page => {
                    onChangeQuery("page", page)
                  }}
                />
              </div>
              <div className='mapping-control'>
                <label>Sort</label>
                <select className='form-control' value={query.sort} onChange={(e) => onChangeQuery("sort", e.target.value)}>
                  <option value="lastUpdated">Last Updated</option>
                  <option value="key">Key</option>
                </select>
              </div>
              <div className='mapping-control'>
                <label>Type</label>
                <select className='form-control' value={query.type} onChange={(e) => onChangeQuery("type", e.target.value)}>
                  <option value="all">All</option>
                  <option value="regex">Regex</option>
                  <option value="label">Label</option>
                  <option value="merchant">Merchant</option>
                </select>
              </div>
              <div className='mapping-control'>
                <label>Companies Defined</label>
                <select className='form-control' value={query.companiesDefined} onChange={(e) => onChangeQuery("companiesDefined", e.target.value)}>
                  <option value="all">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              
              <div className='mapping-control'>
                <label>Search</label>
                <input
                  className='form-control'
                  type="text"
                  value={query.search}
                  onChange={(e) => onChangeQuery("search", e.target.value)}
                  placeholder='Search...'
                />
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={fetchMappings}
                disabled={loading}
              >
                <i className="fa fa-search" />
              </button>
            </div>
            { mappings.length === 0 && <h5>No mappings found</h5>}
            { mappings.length > 0 && mappings.map((mapping, idx) => 
              <MappingItem
                mapping={mapping}
                key={idx}
                loading={loading}
                deleteMapping={deleteMapping}
                fetchTransactions={fetchTransactions}
                isSelected = {selectedMapping === mapping._id.toString()}
              />)
            }
          </div>
          <div className='col secondary'>
            {
              selectedMapping ?
              <TransactionsColumn transactions={transactions}/>
              : <h5 className='transactions-column'>No mapping selected</h5>
            }
          </div>
        </div>
        
      </div>
    </div>
  )
}

const TransactionsColumn = ({transactions}: any) => {
  return (
    <div className='transactions-column'>
      <h5>Matching Transaction Names</h5>
      <p style={{fontWeight: "bold"}}>{transactions.length === 0 ? "No matching transactions" : `Showing ${transactions.length}`}</p>
      <div className='items'>
      {
        transactions.map((tx, idx) =>
            <div key={idx} className='transaction'>
              <p>{tx.name}</p>
              <hr/>
            </div>
        )
      }
      </div>
      
    </div>
  )
}

type MappingItemProps = {
  mapping: ModeratedMapping;
  loading: boolean;
  deleteMapping: (mappingId: string) => void;
  fetchTransactions: (mappingId: string) => void;
  isSelected: boolean;
}

const MappingItem = ({mapping, loading, deleteMapping, fetchTransactions, isSelected}: MappingItemProps) => {
  return (
    <div className={`mapping-item ${isSelected ? "selected-mapping" : ""}`} onClick={() => fetchTransactions(mapping._id.toString())} >
      <div className='texts'>
        <div>
          <p style={{fontWeight: "bold"}}>Key: {mapping.key}</p>
          <p style={{fontWeight: "bold"}}>Key Type: {mapping.keyType}</p>
        </div>
        <div className='mapping-row'>
          <p>Approval: {mapping.approval}</p>
          <p>Target: {mapping.target}</p>
          <p>Last Updated: {new Date(mapping.lastUpdated).toLocaleDateString()}</p>
        </div>
        <p>{mapping.company ? `Associated Company: ${mapping.company.name}` : "No company associated"}
        {
          mapping.company ?
          <a style={{ marginLeft: '0.5em', cursor: 'pointer' }}
            onClick={() => {
              window.open('/companies/' + mapping.company._id, 'new')
            }}><FaExternalLinkAlt />
          </a> : null
        }
        </p>
      </div>
      <div className='buttons'>
        <button
          className='btn btn-danger'
          style={{ cursor: 'pointer' }}
          onClick={ev => {
            if (loading) return
  
            const proceed = window.confirm([
              "Are you sure you want to delete this mapping?","",
              "Mapping key: " + mapping.key,"",
              "Mapping id: "  + mapping._id.toString()
            ].join('\n'))
  
            if (proceed) {
              deleteMapping(mapping._id.toString())
            }
          }}
        >
          Delete
        </button>
      </div>
    </div>
  )
}

export default connect(s => s)(MappingManager)
