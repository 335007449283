import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import { Campaign, Company } from '@ecountabl/lib'
import Spinner from './spinner'
import notify from './notifications-helper'
import api from './api-client'
import VerifiedCompanySearch from './verified-company-search'

export type CampaignEditorProps = {
  campaign: Campaign
  onSave?: (campaign: Campaign) => void
}

export const CampaignEditor = ({ campaign, onSave }: CampaignEditorProps) => {
  const dispatch = useDispatch()

  const [current, setCurrent] = useState(campaign)
  const [mapToCo, setMapToCo] = useState<Company>()

  useEffect(() => {
    if (campaign.mapsToCompanyId) {
      api.getCompany(campaign.mapsToCompanyId).then(co => setMapToCo(co))
    } else {
      setMapToCo(undefined)
    }

    setCurrent(campaign)
  }, [campaign])

  const adCount = current.deals?.reduce((prev, curr) => (curr.ads?.length || 0) + prev, 0)

  return <div className="current-campaign">
    <h3>Campaign Details</h3>
    <h5>{current.CampaignName}</h5>
    <p>{current.CampaignDescription}</p>

    {adCount == 0 ? (
      <div className="alert alert-warning">
        This campaign has no active ads, so it will not currently show in the app
      </div>
     ) : null}

    <div className="form-group">
      <label>Company for Campaign</label>
      <VerifiedCompanySearch
        company={mapToCo}
        onSelect={(id, co) => {
          setMapToCo(co)

          setCurrent({
            ...current,
            mapsToCompanyId: id
          })
        }}
        initialSearch={mapToCo?.name}
        placeholder="Company Search"
        hideNoResults={true}
        autoFocus={false}
        resultsStyle='dropdown-only'
      />
    </div>

    <div className="form-group">
      <label>Active</label>
      <span className="form-group">
        <input
          type="checkbox"
          checked={current.active || false}
          onChange={() => {
            setCurrent({
              ...current,
              active: !current.active
            })
          }}
        />
      </span>
    </div>

    <div className="buttons">
      <button type="button" className="btn btn-primary" onClick={() => {
        api.updateCampaign(current).then(res => {
          dispatch(notify.success({ message: `successfully saved campaign` }))
          if (onSave && typeof onSave === 'function') {
            onSave(current)
          }
        }).catch(ex => {
          dispatch(notify.error({ message: `error saving campaign: ${ex.message}` }))
        })
      }}>Save</button>
    </div>

    <h5>Example Deals</h5>
    <p>
      Deals change frequently, sometimes hour-by-hour. Before marking a campaign as live, it's worthwhile to review
      deals for quality.
    </p>
    <div className="list-group">
      {current.deals.map(d => (
        <div key={d.Id} className="deal list-group-item">
          <div className="deal-name">{d.Name}</div>
          <div className="deal-details">
            <div>State: {d.State}</div>
            <div>Start: {d.StartDate}</div>
            <div>End: {d.EndDate}</div>
            <div>Scope: {d.Scope}</div>
            {/* <pre>{JSON.stringify(d, false, ' ')}</pre> */}
          </div>
          <div>
            <div>Ads</div>
            {!d.ads?.length ? <span>This deal has no active ads</span> : null}
            {d.ads?.map(ad => (
              <div key={ad.Id}>
                <div>Name: {ad.Name}</div>
                <div>Type: {ad.Type}</div>
                {/* <pre>{JSON.stringify(ad, false, ' ')}</pre> */}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
}

export default CampaignEditor