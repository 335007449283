import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../spinner'
import actions from '../redux/actions'
import api, { LogoSearchSource, LogoSearchParams } from '../api-client'
import { mergeCompanies } from './merge-company-utils'
import update from 'immutability-helper';
import notify from '../notifications-helper'
import ScoreTinkerer from './score-tinkerer'
import querystring from 'querystring'
import ImageUploader from '../image-uploader'
import InputField from './input-field'
import Ratings from './ratings'
import TagInput from '../tag-input'
import MergeNotification from './merge-notification'
import ChildCompanies from '../child-companies'
import CorporateParentEditor from './corporate-parent-editor'
import { REACT_APP_SERVER_ROOT, REACT_APP_CDN_ROOT } from '../config'
import { Company, getCompanyDescription, logoUrl } from '@ecountabl/lib'

const CUSTOMER_TEMPLATE = {
  "name": "",
  // "cc": "",
  "corporate_parent": "",
  "corporate_parent_id": "",
  "logoref": "",
  "logourl": "", // from ritekit
  // "nd": "",
  // "pd": "",
  // "search_name": "",
  "segment": "",
  "sub_segment": "",
  "ticker": "",
  "twitter": "",
  "website": "",
  "source": "",
  "ID": "",
  ratings: {},
  tags: []
}

const FIELD_CONFIG = {
  "name": { Component: InputField },
  "corporate_parent_id": { Component: InputField },
  "website": { Component: InputField },
  "logoref": { Component: InputField, props: { readOnly: true } },
  "logourl": { Component: InputField }, // from ritekit
  "ticker": { Component: InputField },
  "twitter": { Component: InputField },
  "segment": { Component: InputField },
  "sub_segment": { Component: InputField },
  "tags": { Component: InputField },
  "city": { Component: InputField },
  "state": { Component: InputField },
  "userFreeFormInput": {
    Component: InputField,
    props: {
      label: "User Free Form Input",
      hideIfEmpty: true,
      readOnly: true
    }
  },
  "dateSubmitted": {
    Component: InputField,
    props: {
      label: "Date Submitted",
      hideIfEmpty: true,
      readOnly: true
    }
  },
  "submittingUser": {
    Component: InputField,
    props: {
      label: "Submitted By",
      hideIfEmpty: true,
      readOnly: true
    }
  },
  "unknownSocial": {
    Component: InputField,
    props: {
      label: "Social Handle (network unknown)",
      hideIfEmpty: true,
      readOnly: true
    }
  },
  "source": { Component: InputField, props: { readOnly: true } },
  "ID": { Component: InputField, props: { readOnly: true } },
  "description": { Component: InputField },
}

const SECTIONS = {
  "Company Fields": {
    fields: [
      // sorting here affects order
      "name", "corporate_parent_id", "website", "logoref", "logourl",
      "segment", "sub_segment", "tags", "city", "state", "twitter", "ticker",
      "source", "ID", "description"
    ]
  },
  "Featured Information": {
    fields: ["featuredImage", "featuredBlurb"]
  },
  "Moderation Details": {
    fields: ["userFreeFormInput", "dateSubmitted", "submittingUser", "unknownSocial"]
  }
}

class CompanyEditor extends Component {
  state = {
    loading: true,
    saving: false,
    company: {},
    segments: [],
    tagOptions: [],
    indicatorGroups: {
      byCategory: {},
      byAbbreviation: {},
      indicators: []
    },
    moderate: false,
    mergeTargetCo: {},
    mergeSourceCo: {},
    mergeSourceText: '',
    logoQuery: undefined,
    new: false,
    showShareImages: false,
  }

  componentDidMount() {
    const template = JSON.parse(JSON.stringify(CUSTOMER_TEMPLATE))
    let getCompanyProm
    const { id } = this.props.match.params

    const qstr = window.location.search.substr(1)
    const queryParams = querystring.parse(qstr)

    if (id === "new") {
      const { name } = queryParams
      template.name = name
      this.setState({ company: template, new: true, })
    } else if (this.props.moderate) {
      getCompanyProm = api.getCompanyUnderModeration(id)
    } else if (this.props.merge) {
      const mergeSourceText = queryParams.sourceType === 'moderation' ? 'moderation queue' : 'existing companies'
      this.setState({ mergeSourceText })

      getCompanyProm = this.getMergedDraftCompany(id, queryParams)
    } else {
      getCompanyProm = api.getCompany(id)
    }

    Promise.all([
      api.getSegments(),
      api.getAdminIndicators(),
      api.getTags(),
      getCompanyProm
    ]).then(([segments, indicatorGroups, tagOptions, company]) => {
      if (company && !(company?.logoref || company?.logourl)) {
        const source = this.props.moderate ? LogoSearchSource.CsrHub : LogoSearchSource.None
        this.setState({ logoQuery: { id, source } })
      }

      if (company) {
        company.tags = company.tags || []
        this.setState({ company })
      }

      this.setState({
        loading: false,
        segments: segments.sort((a, b) => a.name.localeCompare(b.name)),
        tagOptions: tagOptions.sort((a, b) => a._id.localeCompare(b._id)).map(t => t._id),
        indicatorGroups
      })
    }).catch(ex => this.props.dispatch(notify.error({ message: ex.message })))
  }

  getMergedDraftCompany(targetId, { sourceType, sourceId }) {
    const getCoProm = sourceType === 'moderation' ? api.getCompanyUnderModeration(sourceId) : api.getCompany(sourceId)

    return new Promise(async (resolve, reject) => {
      try {
        const [targetCo, sourceCo] = await Promise.all([
          api.getCompany(targetId),
          getCoProm
        ])

        // console.log({ targetCo, sourceCo })
        this.setState({
          mergeTargetCo: targetCo,
          mergeSourceCo: sourceCo
        })

        const mergeCo = mergeCompanies(sourceCo, targetCo)
        return resolve(mergeCo)
      } catch (ex) {
        reject(ex)
      }
    })
  }

  submit(ev) {
    ev.preventDefault()
    const { company } = this.state

    company.description = getCompanyDescription(company as Company)

    const prom = this.state.new ? api.newCompany(company) : api.updateCompany(company)
    prom.then(res => {
      this.props.dispatch(notify.success({ message: "Company saved successfully" }))

      if (this.state.new) {
        // redirect to canonical page to avoid an accidental re-create of same company
        this.props.history.push(`/companies/${res._id}`)
      }
    }).catch(ex => this.props.dispatch(actions.ajaxErrorHandler(ex)))
  }

  onDelete(ev) {
    const del = window.confirm([
      `Are you sure you want to delete this company?`,
      `You cannot undo this action!`
    ].join(' '))

    const { company } = this.state

    if (del) {
      api.deleteCompany(company._id).then(res => {
        this.props.dispatch(
          notify.success({ message: `Successfully deleted ${company.name}` })
        )
        this.props.history.push('/moderate')
      }).catch(ex => {
        this.props.dispatch(actions.ajaxErrorHandler(ex))
      })
    }
  }

  fieldChange(field: string, ev) {
    let { value } = ev.target

    // auto-extract hostname
    if (field === 'website') {
      try {
        const url = new URL(ev.target.value)
        value = url.hostname
      } catch (ex) {
        /* noop; TODO: add better validation handling */
      }
    } else if (field.startsWith('featured_info')) {
      const [_, subfield] = field.split('.')

      if (!this.state.company.featured_info) {
        this.setState({
          company: {
            ...this.state.company,
            featured_info: {}
          }
        }, () => {
          // Perform the update once featured_info is guaranteed to exist
          this.setState({
            company: update(this.state.company, {
              featured_info: { [subfield]: { $set: value } }
            })
          })
        })
      } else {
        // Perform the update directly if featured_info already exists
        this.setState({
          company: update(this.state.company, {
            featured_info: { [subfield]: { $set: value } }
          })
        })
      }

      return
    }

    this.setState({
      company: update(this.state.company, {
        [field]: { $set: value }
      })
    })
  }

  ratingsChange(abb: string, ev) {
    let { value } = ev.target

    const company = { ...this.state.company }
    company.ratings[abb] = value
    this.setState({ company })
  }

  async logoChange(logopath: string) {
    const company = { ...this.state.company }
    company.logourl = logopath    
    company.webpurl = undefined // this will get updated on company change
    this.setState({ company })
  }

  onSegmentChange(e) {
    const segment = this.state.segments.find(s => e.target.value === s.name)

    this.setState({
      company: update(this.state.company, {
        segment: { $set: e.target.value }
      }),
      segment
    })
  }

  onSubSegmentChange(e) {
    this.setState({
      company: update(this.state.company, {
        sub_segment: { $set: e.target.value }
      })
    })
  }

  async onApprove(ev) {
    const { company } = this.state
    this.setState({ saving: true })
    try {
      await api.approveCompany(company._id, company)
      this.props.dispatch(notify.success({ message: `Approved Company ${company.name}` }))
      this.props.history.push('/moderate')
    } catch (ex) {
      this.setState({ error: ex, saving: false })
    }
  }

  async onMerge(ev) {
    const { company, mergeSourceCo, mergeSourceText } = this.state
    try {
      this.setState({ saving: true })
      await api.updateCompany(company)
      await api.deleteCompanyUnderModeration(mergeSourceCo._id)
      this.props.dispatch(notify.success({
        message: `successfully merged companies and deleted from ${mergeSourceText}`
      }))
      this.props.history.push('/moderate')
    } catch (ex) {
      console.log('error merging companies', ex)
      this.setState({ error: ex, saving: false })
      this.props.dispatch(notify.error({ message: ex.message }))
    }
  }

  // also used for marking as archived
  async saveInModerationQueue(msg?: string) {
    const { company } = this.state
    this.setState({ saving: true })
    const message = typeof msg === 'string' ? msg : `Saved '${company.name}' in moderation queue`
    try {
      await api.putCompanyUnderModeration(company._id, company)
      this.props.dispatch(notify.success({ message }))
      this.props.history.push('/moderate')
    } catch (ex) {
      this.setState({ error: ex, saving: false })
    }
  }

  getSubmitButton() {
    if (this.state.saving) {
      return <Spinner />
    }

    if (this.props.moderate) {
      return (
        <div>
          <button
            className="btn btn-primary"
            type="button"
            onClick={this.onApprove.bind(this)}>Approve</button>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={this.saveInModerationQueue.bind(this)}>Save in Moderation Queue</button>
          <button
            className="btn btn-warning"
            type="button"
            onClick={() => {
              const company = { ...this.state.company }
              company.moderationStatus = 'ignore'
              this.setState({ company }, () => {
                const msg = `Successfully ignored '${company.name}', will no longer be shown in moderation`
                this.saveInModerationQueue(msg)
              })
            }}>Mark as Ignored</button>
        </div>
      )
    } else if (this.props.merge) {
      const { mergeTargetCo, mergeSourceCo, mergeSourceText } = this.state

      return (
        <button
          className="btn btn-primary"
          type="button"
          onClick={this.onMerge.bind(this)}>
          Merge '{mergeSourceCo.name}' into '{mergeTargetCo.name}'
          and delete '{mergeSourceCo.name}' from {mergeSourceText}
        </button>
      )
    } else {
      return <>
        <button className="btn btn-primary" type="submit">Save</button>
        <button
          className="btn btn-danger"
          onClick={this.onDelete.bind(this)}
          type="button"
        >Delete</button>
      </>
    }
  }

  render() {
    const { error, loading, indicatorGroups, company } = this.state

    if (loading) {
      return <div><Spinner /></div>
    }

    if (error) {
      return <div>
        Error: {JSON.stringify(error)}
      </div>
    }

    const imgSrc = logoUrl(company, REACT_APP_CDN_ROOT)
    const companyFields = Object.keys(FIELD_CONFIG).filter(k => !['segment', 'sub_segment', 'tags'].includes(k))
    const sortedCategories = Object.keys(indicatorGroups.byCategory).sort((a, b) => a.localeCompare(b))

    const { segments, logoQuery, mergeSourceCo, mergeTargetCo, mergeSourceText } = this.state
    const segment = segments.find(s => s.name === company.segment)

    return <div className="company-editor">
      <h2>{company.name}</h2>
      <div style={{ textAlign: 'center' }}>
        <ImageUploader
          imgSrc={imgSrc}
          onChange={this.logoChange.bind(this)}
          enableSearch
          searchQuery={logoQuery}
          onSearchResponse={({ domain }) => {
            if (domain) {
              const company = { ...this.state.company }
              if (!company.website) {
                company.website = domain
                this.setState({ company })
              }
            }
          }}
        />
        <p className="all-white-disclaimer"> note that all-white images will not look good in the app </p>
      </div>
      <MergeNotification
        merge={this.props.merge}
        mergeSourceCo={mergeSourceCo}
        mergeTargetCo={mergeTargetCo}
        mergeSourceText={mergeSourceText}
      />
      <form onSubmit={this.submit.bind(this)}>
        <div>
          {Object.entries(SECTIONS).map(([key, { fields }]) => {
            if (key === "Featured Information") {
              if (company.tags.includes("CFB Featured")) {
                return (
                  <div key={key}>
                    <h4>{key}</h4>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", gap: 50}}>
                      {fields.map(field => {
                        if (field === 'featuredImage') {
                          return <div key="featuredImage" style={{display:"flex",flexDirection: "column"}}>
                            <label style={{fontWeight: "bold"}}>Featured Image</label>
                            <span>square images suggested for best UI</span>
                            <ImageUploader
                              imgSrc={company.featured_info?.featured_image}
                              onChange={(imgPath) => {
                                this.fieldChange('featured_info.featured_image', { target: { value: imgPath } })
                              }}
                              enableSearch={false}
                              hidePrompt
                              targetSize={{ width: 200, height: 200 }}
                            />
                            <p>{company.featured_info?.featured_image ? `Featured Image: ${company.featured_info.featured_image}` : "No featured image set. Click the logo to set one."}</p>
                            {company.featured_info?.featured_image &&
                            <button className='btn btn-danger' onClick={(e) => {
                              e.preventDefault()
                              this.fieldChange('featured_info.featured_image', { target: { value: "" } })
                            }}>Click to remove featured image</button>}
                          </div>
                        } else if (field === 'featuredBlurb') {
                          return <div key="featuredBlurb" style={{display:"flex",flexDirection: "column"}}>
                            <label style={{fontWeight: "bold"}}>Featured Blurb</label>
                            <textarea
                              className="form-control"
                              style={{width: 200, height: "100%", resize: "none", padding: 0}}
                              onChange={(e) => this.fieldChange('featured_info.featured_blurb', e)}
                              value={company.featured_info?.featured_blurb}
                              placeholder={`This is the text that will appear under ${company.name}'s name on their featured card. Make it something that piques interest :)`}
                            />
                          </div>
                        }
                      })}
                    </div>
                  </div>
                )
              }
              return null
            }

            return <div key={key}>
              <h4>{key}</h4>
              <div className="form-fields">
                {fields.map(field => {
                  const { Component, props } = FIELD_CONFIG[field]

                  if (field === 'segment') {
                    return <div key="segment" className="form-group">
                      <label>Segment</label>
                      <select
                        className="form-control"
                        onChange={(e) => this.onSegmentChange(e)}
                        value={segment && segment.name}
                      >
                        <option></option>
                        {this.state.segments.map((s, i) => {
                          return <option key={`${i}-${s.name}`} value={s.name}>{s.name}</option>
                        })}
                      </select>
                    </div>
                  } else if (field === 'sub_segment') {
                    return <div key="subsegment" className="form-group">
                      <label>Sub-Segment (deprecated)</label>
                      <input
                        className="form-control"
                        disabled
                        onChange={(e) => this.onSubSegmentChange(e)}
                        value={company.sub_segment}
                      />
                    </div>
                  } else if (field === 'corporate_parent_id') {
                    return <CorporateParentEditor
                      key='corporate_parent_id'
                      parentId={company.corporate_parent_id}
                      // hack to spoof something that looks like an event
                      onChange={(id, name) => {
                        this.setState({
                          company: update(this.state.company, {
                            corporate_parent: { $set: name },
                            corporate_parent_id: { $set: id }
                          })
                        })
                      }}
                    />
                  } else if (field === 'tags') {
                    return <div key="tags" className="form-group">
                      <label>Tags</label>
                      <TagInput
                        options={this.state.tagOptions || []}
                        tags={company.tags}
                        onAddTag={tag => {
                          if (!this.state.tagOptions.includes(tag)) {
                            this.props.dispatch(notify.error({
                              message: `Are you sure you want to add the new tag "${tag}"?`
                            }))
                          }

                          const tags = [...company.tags]
                          tags.push(tag)
                          this.setState({
                            company: update(this.state.company, { tags: { $set: tags } })
                          })
                        }}
                        onRemoveTag={tag => {
                          const tags = company.tags.filter(t => t !== tag)
                          this.setState({
                            company: update(this.state.company, { tags: { $set: tags } })
                          })
                        }}
                      />
                    </div>
                  } else if (field === 'description') {
                    return <div key="description" className="form-group">
                      <label>description</label>
                      <textarea
                        className="form-control"
                        onChange={(e) => this.fieldChange('description', e)}
                        value={company.description}
                        placeholder={getCompanyDescription(company as Company)}
                      />
                    </div>
                  }

                  return <Component
                    key={field}
                    field={field}
                    onChange={this.fieldChange.bind(this, field)}
                    value={company[field]}
                    {...props}
                  />
                })}
              </div>
            </div>
          })}
        </div>
        <div className="buttons">
          <h4>Actions</h4>
          {this.getSubmitButton()}
        </div>
        {(!this.state.new && !this.props.moderate) && (
          <ChildCompanies
            parent_id={company.corporate_parent_id || company._id}
            parentName={company.corporate_parent || company.name}
          />
        )}
        <h4>Ratings</h4>
        <Ratings
          indicatorGroups={indicatorGroups}
          company={company}
          onRatingsChange={this.ratingsChange.bind(this)}
        />
        <div>
          <h4>Share Images</h4>
          {this.state.showShareImages ? (
            <div>              
              {sortedCategories.map(cat => {
                const abbs = indicatorGroups.byCategory[cat].sort()
                return <div key={cat}>
                  <h5>{cat || "Un-mapped Ratings"} Share Images</h5>
                  <div className="rating-images">
                    {abbs.map(abbreviation => {
                      const rating = company.calculatedRatings[abbreviation]
                      if (rating !== undefined && rating !== null) {
                        const src = `${REACT_APP_SERVER_ROOT}/info/${this.state.company.slug}/img/${abbreviation}.png`
                        return <a key={abbreviation} href={src} target="_blank">
                          <img src={src} height="125" width="250" />
                        </a>
                      }
                    })}
                </div>
                </div>
              })}
            </div>
          ) : (
            <button type="button"
              className="btn btn-secondary"
              onClick={() => { this.setState({ showShareImages: true }) }}
            >Preview Share Images</button>
          )
          }
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2em' }}>
          <div style={{ flex: 1 }}>
            <h4>Calculated Ratings</h4>
            <p>Calculated ratings based on inherited values from parent(s)</p>
            <label></label>
            <div className="calculated-ratings">
              <pre>
                {JSON.stringify(company.calculatedRatings, false, "  ")}
              </pre>
            </div>
          </div>
          <ScoreTinkerer companyId={this.props.match.params.id} style={{ flex: 2 }} />
        </div>
      </form>
    </div>
  }
}

export default connect(state => state)(CompanyEditor)
