import { ObjectID } from "mongodb"

export enum ApprovalType {
  Admin = 'admin',
  User = 'user'
}

export enum TargetType {
  Company = 'company',
  NoMatches = 'no_matches',
  LocalBiz = 'localbiz'
}

export enum KeyType {
  Label = 'label',
  Merchant = 'merchant',
  Regex = "regex"
}

interface RegexMapping {
  key: RegExp;
  keyType: KeyType.Regex;
}

interface StringMapping {
  key: string;
  keyType: KeyType.Label | KeyType.Merchant;
}

type MappingType = RegexMapping | StringMapping

export type Mapping = {
  _id: string | ObjectID;
  companyId?: string;
  approval: ApprovalType;
  target?: TargetType; // empty target means unknown
  txids: string[];
  lastUpdated: Date;
  alternateCompanyIds?: string[];
} & MappingType