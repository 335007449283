enum AccountStatus {
  New = 'NEW',
  InitialUpdate = 'INITIAL_UPDATE',
  HistoricalUpdate = 'HISTORICAL_UPDATE',
  Unlinking = 'UNLINKING',
  Error = 'ERROR',
  Relinking = 'RELINKING'
}

export type AccountInstanceSummary = {
  account_id: string;
  name: string;
  institution: string;
}

export type FinastraAccountType = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_expires_in: number;
  scope: string;
  id_token: string;
  institution: {
    name: string;
    institution_id: string;
  },
  item_id: string;
  status: AccountStatus;
}

export type PlaidAccountType = {
  institution: {
    name: string;
    institution_id: string;
  };
  access_token: string;
  item_id: string;
  status: AccountStatus;
}

export type DashboardAccountInfo = {
  institution: string;
  item_id: string;
  // if good, we have account info
  fromCustomer: boolean | null;
  balances: {
    available?: number;
    current?: number;
  } | null;
  accountName: string | null;
  accountType: string | null;
  accountSubType?: string | null;
  accountNumber: string | null;
  // if bad, we have error info
  error?: boolean;
  account_id: string;
}

export type UserAccount = PlaidAccountType | FinastraAccountType