import React, { useCallback, useMemo, useState } from 'react'
import api from './api-client'
import actions from './redux/actions'
import Transaction from './transaction'
import { connect } from 'react-redux'
import CompanySearch, { CompanySearchWrapper } from './company-search'
import Spinner from './spinner'
import { Company, MatchedPlaidTransaction, PlaidTransaction, regexLiteralFromString } from '@ecountabl/lib'
import Pagination from './pagination'

interface State {
  transactions: any[];
  currentTx?: object;
  sort: string;
  total?: number;
  mapSuccess: boolean;
  page: number;
  loading: boolean;
  searchQuery?: string;
  isMapping: boolean;
  showModerated: boolean;
  regexCompany?: Company;
}

class Transactions extends React.Component<
  { dispatch: (action: any) => void },
  State
> {
  constructor(props: any) {
    super(props)
    this.state = {
      transactions: [],
      total: undefined,
      currentTx: undefined,
      sort: 'date',
      page: 1,
      loading: true,
      mapSuccess: false,
      isMapping: false,
      searchQuery: '',
      showModerated: false,
      regexCompany: null
    }
  }

  onSortChange = (e: any) => {
    this.setState({ sort: e.target.value }, () => {
      this.fetchTransactions()
    })    
  }

  componentDidMount() {
    this.fetchTransactions()
  }

  selectUnknown(tx: object) {
    const { currentTx } = this.state
    if (currentTx?._id === tx._id) {
      this.setState({ currentTx: undefined })  
    } else {
      this.setState({
        currentTx: tx
      })
    }
  }

  fetchTransactions() {
    this.setState({ loading: true })
    const { sort, showModerated, page, searchQuery } = this.state
    api.getTransactions(sort, page, showModerated, searchQuery)
      .then(({ transactions, total }) => {
        this.setState({ transactions, total, loading: false })
      })
      .catch((ex) => this.props.dispatch(actions.ajaxErrorHandler(ex)))
  }

  onMap(companyId: string, regex?: string) {
    this.setState({ isMapping: true })
    const regexVal = this.state.regexCompany ? regex : null
    api.mapTransaction(this.state.currentTx?._id, companyId, regexVal)
      .then((res) => {
        this.setState({ mapSuccess: true })

        setTimeout(() => {
          this.setState({ currentTx: undefined, mapSuccess: false })
          // reload the transactions
          this.fetchTransactions()
        }, 1500)
      })
      .catch((ex) => this.props.dispatch(actions.ajaxErrorHandler(ex)))
      .finally(() => this.setState({ isMapping: false, regexCompany: null}))
  }

  defineRegex(co: Company) {
    this.setState({regexCompany: co})
  }

  render() {
    let { loading, transactions, currentTx, mapSuccess, isMapping } = this.state

    let rightPanel = <React.Fragment />

    if (currentTx && !mapSuccess && !isMapping) {
      let initialSearch = currentTx?.merchant_name || currentTx?.name
      initialSearch = initialSearch.replace(/,? ?(Corp\.|Co\.|Inc\.|LL[PC]|Ltd)/gi, '').trim(',')

      rightPanel = (
        <CompanySearchWrapper
          onSelect={this.onMap.bind(this)}
          autoSearch={true}
          initialSearch={initialSearch}
          placeholder="Company Search"
          onRegex={this.defineRegex.bind(this)}
          resultsStyle='regex'
        />
      )
    } else {      
      rightPanel = (
        <div className="card bg-light">
          <div className="card-body">
            {isMapping ? <div style={{ textAlign: 'center' }}><Spinner /></div> : (
              mapSuccess ? 'Mapping successful!' : 'Select a transaction to map it to a company'
            )}
          </div>
        </div>
      )
    } 

    return (
      <div className="unknowns-mgr">
        <div style={{ width: '100%' }}>
          <div className="tx-hdr">
            <h3>Transaction Moderation</h3>
          </div>
          <div className="transactions-layout">
            <div className="transactions-list">
              <div className="control-wrapper">
                {loading ? <Spinner /> : null}
                <p className="count">
                  There are <b>{this.state.total}</b> total transactions matching the current search;
                  max of 100 are showed at a time
                </p>
                <div className="controls">
                  <label>Sort</label>
                  <select
                    className="form-control"
                    onChange={(e) => this.onSortChange(e)}
                    value={this.state.sort}
                  >
                    <option value="merchant">Merchant Name</option>
                    <option value="label">Label</option>
                    <option value="date">Date</option>
                  </select>
                  <Pagination
                    page={this.state.page}
                    onChange={page => {
                      this.setState({ page }, this.fetchTransactions)
                    }}
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.searchQuery}
                    placeholder="Search"
                    style={{ maxWidth: '250px' }}
                    onChange={(ev) => {
                      this.setState({
                        searchQuery: ev.target.value
                      })
                    }}
                  />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.fetchTransactions()}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
                <div className="controls">
                  <label>Include Already Moderated</label>
                  <input
                    className="form-control"
                    type="checkbox"
                    onChange={ev => { 
                      this.setState({ 
                        showModerated: !this.state.showModerated
                      }, () => this.fetchTransactions())
                    }}
                  />
                </div>
              </div>
                <ul className="list-group">
                  {transactions.map((tx) => {
                    let au = tx._id === currentTx?._id ? 'list-group-item-primary' : ''
                    return (
                      <Transaction
                        className={`transaction list-group-item ${au}`}
                        key={tx._id}
                        tx={tx}
                        onClick={this.selectUnknown.bind(this, tx)}
                        onResolved={() => {
                          this.fetchTransactions()
                          this.setState({ currentTx: undefined, mapSuccess: false })
                        }}
                      />
                    )
                  })}
                </ul>
              </div>
            <div className="map-unknown right-panel">
              {rightPanel}
            </div>
          </div>
        </div>
        {
          this.state.regexCompany ?
            <RegexModal
              defineRegex={this.defineRegex.bind(this)}
              onSubmit={this.onMap.bind(this)}
              currentTx={this.state.currentTx as MatchedPlaidTransaction}
              company={this.state.regexCompany}
            />
          : null
        }
      </div>
    )
  }
}

type RegexModalProps = {
  defineRegex: (txName: string, co: Company) => void;
  onSubmit: (companyId: string, regex: string) => void;
  currentTx: MatchedPlaidTransaction;
  company?: Company;
}

const RegexModal = ({defineRegex, onSubmit, currentTx, company}: RegexModalProps) => {
  const [regexString, setRegexString] = useState('')

  const exit = useCallback(() => {
    defineRegex(null, null)
  }, [])

  const isRegexMatching = useMemo(() => {
    if (regexString.length == 0) return false

    try {
      const regex = regexLiteralFromString(regexString)
      return regex.test(currentTx.matching.dedupe_key)
    } catch (ex) {
      return false
    }
  },[currentTx, regexString])

  const regexBorder = () => {
    if (regexString.length == 0) {
      return ""
    } else if (isRegexMatching) {
      return "1px solid green"
    } else {
      return "1px solid red"
    }
  }

  return (
    <div className='central-modal' onPointerDown={exit}>
      <div className='modal-content' onPointerDown={(e) => e.stopPropagation()}>
        <h1>Regular Expression Company Matching</h1>
        <div>
          <h3>Transaction Name</h3>
          <p>{currentTx.name}</p>
          <p>(transaction key: {currentTx.matching.dedupe_key})</p>
          <h3>Company</h3>
          <p>{company.name}</p>
        </div>
        <div>
          <h2>Regular Expression</h2>
          <p>{regexString}</p>
          <input
            className='form-control'
            type="text"
            placeholder="Enter a regex"
            value={regexString}
            onChange={(ev) => setRegexString(ev.target.value)}
            style={{border: regexBorder()}}
          />
          {
            regexString.length == 0 ? null :
            isRegexMatching ? <p style={{color: "green"}}>Regex matches the transaction key</p> :
            <p style={{color: "red"}}>Regex does not match the transaction key</p>
          }
        </div>
        <div style={{display: "flex", flexDirection: "row", gap: "5em", margin: "2em 0 0 0"}}>
          <button className='btn btn-secondary' onClick={exit}>Close</button>
          <button className='btn btn-primary' onClick={() => onSubmit(company._id, regexString)} disabled={!isRegexMatching}>Submit</button>
        </div>
      </div>
    </div>
  )
}

export default connect((s) => s)(Transactions)
